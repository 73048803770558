import React from 'react';
import Payment from 'views/Payment';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const PaymentPage = () => {
  return (
    <WithLayout
      component={Payment}
      layout={Main}
    />
  )
};

export default PaymentPage;
