import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  colors,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { graphql, useStaticQuery } from 'gatsby'

const useStyles = makeStyles(theme => ({
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  coverImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const About = props => {
  const data = useStaticQuery(graphql `
    query {
      pages: allContentfulPages(filter: {id: {eq: "9d7a8363-7734-5ed4-ab1a-fbb435cd51e6"}}) {
        edges {
          node {
            title
            id
            body {
              internal {
                content
              }
            }
            bodyExtra {
              internal {
                content
              }
            }
            imageOne {
              file {
                url
              }
            }
            subTitleOne
            subBodyOne {
              internal {
                content
              }
            }
            subTitleTwo
            subBodyTwo {
              internal {
                content
              }
            }
            subTitleThree
            subBodyThree {
              internal {
                content
              }
            }
          }
        }
      }
    }
  `)
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      {data.pages.edges.map((edge) => {
            return (
              <div>
                  <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
                    <Grid item xs={12} md={6} data-aos="fade-up">
                      <SectionHeader
                        title={edge.node.title}
                        subtitle="Your customers can make payments via many avalible payment options. "
                        align="left"
                        disableGutter
                      />
                      <List disablePadding>
                        
                          <ListItem disableGutters data-aos="fade-up">
                            <ListItemAvatar className={classes.listItemAvatar}>
                              <IconAlternate
                                size="small"
                                fontIconClass="fas fa-credit-card"
                                color={colors.blue}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={edge.node.subTitleOne} secondary={edge.node.subBodyOne.internal.content} />
                          </ListItem>

                          <ListItem disableGutters data-aos="fade-up">
                            <ListItemAvatar className={classes.listItemAvatar}>
                              <IconAlternate
                                size="small"
                                fontIconClass="fas fa-mobile-alt"
                                color={colors.blue}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={edge.node.subTitleTwo} secondary={edge.node.subBodyTwo.internal.content} />
                          </ListItem>

                          <ListItem disableGutters data-aos="fade-up">
                            <ListItemAvatar className={classes.listItemAvatar}>
                              <IconAlternate
                                size="small"
                                fontIconClass="fas fa-coins"
                                color={colors.blue}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={edge.node.subTitleThree} secondary={edge.node.subBodyThree.internal.content} />
                          </ListItem>
                      
                      </List>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      xs={12}
                      md={6}
                      data-aos="fade-up"
                    >
                    <Image
                      src={edge.node.imageOne.file.url}
                      alt="..."
                      width="450px"
                      className={classes.coverImage}
                    />
                    </Grid>
                  </Grid>
              </div>
            )
        })}
    </div>
  );
};

About.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default About;
